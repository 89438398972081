<template>
  <div>
    <app-navbar />
    <div
      v-if="!isSent"
      class="auth-wrapper auth-v1 px-2"
    >
      <div class="py-2">
        <!-- Login v1 -->
        <b-card class="mb-0 login-v1">
          <b-card-text class="mb-2 text-left">
            Type your email address to receive a password reset link
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="forgotForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="forgot"
            >

              <!-- email -->
              <b-form-group
                label-class="inputLabel"
                label-for="email"
                label="Email Address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email Address"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    class="boxShadow"
                    name="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Type..."
                    autofocus
                    :tabindex="tabIndex.tabIndex1"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link
                  :to="{ name:'auth-login' }"
                  class="mt-2 forgot-password-link"
                  :tabindex="tabIndex.tabIndex2"
                >
                  <small class="subtitle text-decoration-none">{{ $t('Back to Log In') }}</small>
                </b-link>
              </b-form-group>
              <!-- submit button -->
              <b-button
                variant="success"
                type="submit"
                :disabled="invalid || isSending"
                :tabindex="tabIndex.tabIndex3"
                class="btn font-weight-bolder content-right"
              >
                {{ $t('Send Reset Link') }}
              </b-button>
            </b-form>
          </validation-observer>

        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
    <div
      v-else
      class="auth-wrapper auth-v1 px-2"
    >
      <div class="py-2">
        <!-- Login v1 -->
        <b-card class="mb-0 login-v1">
          <b-card-text class="mb-2 text-left font-weight-bolder h4 text-primary text-center">
            {{ message }}
          </b-card-text>
          <!-- form -->
          <div class="d-flex justify-content-center">
            <b-button
              variant="success"
              class="btn mt-0"
              @click="$router.push({ name: 'auth-login' })"
            >
              {{ $t('Back to Log In') }}
            </b-button>
          </div>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput,
  BFormGroup, BCard, BCardText, BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import AppNavbar from '@/@core/layouts/components/app-navbar/AppNavbar.vue'
import { scrollToError } from '@core/utils/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BLink,
    ValidationProvider,
    ValidationObserver,
    AppNavbar,
  },
  data() {
    return {
      email: '',
      // validation rules
      required,
      isSending: false,
      isSent: false,
      message: '',
      tabIndex: {
        tabIndex1: 1,
        tabIndex2: 2,
        tabIndex3: 3,
      },
    }
  },
  computed: {
  },
  methods: {
    forgot() {
      this.$refs.forgotForm.validate().then(success => {
        if (success) {
          this.isSending = true
          useJwt.forgotPassword({
            email: this.email,
          })
            .then(response => {
              this.email = ''
              this.isSent = true
              this.message = response.data.message
            })
            .catch(error => {
              this.errorNotification(this, error)
            }).finally(() => {
              this.isSending = false
            })
        } else {
          scrollToError(this, this.$refs.forgotForm)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
