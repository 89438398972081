<template>
  <div>
    <!-- Image and text -->
    <b-navbar
      variant="faded"
      type="dark"
      class="navbar"
    >
      <b-navbar-brand href="#">
        <img
          width="100px"
          height="60px"
          :src="logo"
          class="d-inline-block align-top"
          alt="logo"
        >
      </b-navbar-brand>
    </b-navbar>
  </div>
</template>

<script>
import { BNavbar, BNavbarBrand } from 'bootstrap-vue'
import logo from '../../../../assets/images/logo/easyRBackOffice.svg'

export default {
  components: {
    BNavbar,
    BNavbarBrand,
  },
  data() {
    return {
      logo,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/base/pages/app-navbar.scss';
</style>
